<template>
  <b-overlay
    :show="isEmpty(accountPayable)"
    rounded="sm"
    no-fade
    variant="transparent"
    :opacity="0.5"
  >
    <b-card id="detail-accounts-payable-card">
      <!-- SECTION HEADER INFORMATION -->
      <div class="d-flex-center justify-content-between">
        <!-- ANCHOR LEFT INFO -->
        <div>
          <div>
            <span class="text-heading-4">{{ $t('accountsPayable.agCode') }}:</span>
            {{ agencyCode }}
          </div>
          <div>
            <span class="text-heading-4">{{ $t('accountsPayable.agName') }}:</span>
            {{ agencyName }}
          </div>
        </div>
        <!-- ANCHOR Right button -->
        <div>
          <!-- ANCHOR Export -->
          <b-button
            class="px-lg-1 px-sm-75 py-50"
            variant="flat-info"
            :disabled="!canAccess('acc_payable.exportAccpayble')"
            @click="confirmExport()"
          >
            <span
              class="text-nowrap"
            >
              <img
                src="@icons/export-excel.svg"
                class="cursor-pointer"
                size="16"
              >
              <span class="d-none d-sm-inline ml-50">{{ $t('export') }}</span>
            </span>
          </b-button>
        </div>
      </div>
      <!-- !SECTION -->

      <!-- SECTION INFORMATION CARD-->
      <div class="mt-1">
        <app-collapse
          type="border"
        >

          <app-collapse-item
            title
            is-visible
          >
            <template #header>
              <span class="text-heading-3 text-info text-uppercase">{{ $t('accountsPayable.detail.information') }}</span>
            </template>

            <!-- ANCHOR Content information -->
            <div>
              <b-row class="mt-50">
                <b-col
                  cols="4"
                  class="text-heading-5"
                >
                  {{ $t('accountsPayable.date') }}:
                </b-col>
                <b-col
                  cols="8"
                >
                  {{ convertISODateTime(query.startDate).date }} - {{ convertISODateTime(query.endDate).date }}
                </b-col>
              </b-row>
              <b-row class="mt-50">
                <b-col
                  cols="4"
                  class="text-heading-5"
                >
                  {{ $t('accountsPayable.tradeCreditors') }}:
                </b-col>
                <b-col
                  cols="8"
                >
                  {{ formatCurrency(accountPayable.totalTradeCreditor) }}
                </b-col>
              </b-row>
              <b-row class="mt-50">
                <b-col
                  cols="4"
                  class="text-heading-5"
                >
                  {{ $t('accountsPayable.openingBalance') }}:
                </b-col>
                <b-col
                  cols="8"
                >
                  {{ formatCurrency(accountPayable.totalOpeningBalance) }}
                </b-col>
              </b-row>
              <b-row class="mt-50">
                <b-col
                  cols="4"
                  class="text-heading-5"
                >
                  {{ $t('accountsPayable.receivables') }}:
                </b-col>
                <b-col
                  cols="8"
                >
                  {{ formatCurrency(accountPayable.totalReceivables) }}
                </b-col>
              </b-row>
              <b-row class="mt-50">
                <b-col
                  cols="4"
                  class="text-heading-5"
                >
                  {{ $t('accountsPayable.closingBalance') }}:
                </b-col>
                <b-col
                  cols="8"
                >
                  {{ formatCurrency(accountPayable.totalClosingBalance) }}
                </b-col>
              </b-row>
              <b-row class="mt-50">
                <b-col
                  cols="4"
                  class="text-heading-5"
                >
                  {{ $t('accountsPayable.unpaidCommission') }}:
                </b-col>
                <b-col
                  cols="8"
                >
                  {{ formatCurrency(accountPayable.totalUnpaidCommission) }}
                </b-col>
              </b-row>
            </div>
          </app-collapse-item>
        </app-collapse>
      </div>
      <!-- !SECTION -->

      <!-- SECTION TRANSACTION CARD-->
      <div class="mt-1">
        <app-collapse
          type="border"
        >
          <app-collapse-item
            title
            is-visible
          >
            <template #header>
              <span class="text-heading-3 text-info text-uppercase">{{ $t('accountsPayable.detail.transaction') }}</span>
            </template>

            <!-- ANCHOR Table -->
            <b-table
              style="max-height: 100%"
              :sticky-header="true"
              class="position-relative"
              :items="accountPayable.items"
              responsive
              bordered
              :fields="tableDetailColumns"
              primary-key="id"
              show-empty
              :empty-text="$t('noMatchingResult')"
              no-border-collapse
              small
            >
              <template
                v-for="column in tableDetailColumns"
                v-slot:[`head(${column.key})`]="data"
              >
                <span
                  :key="column.label"
                  class="text-dark text-nowrap"
                >
                  {{ $t(`accountsPayable.detail.columns.${data.label}`) }}
                </span>
              </template>

              <template
                v-for="column in tableDetailColumns"
                v-slot:[`cell(${column.key})`]="{ item }"
              >
                <!-- item {{ item[column.key] }} -->
                <div
                  :key="column.key"
                  class="text-nowrap"
                  :class="{
                    'text-right': typeof item[column.key] === 'number',
                  }"
                >
                  {{ item[column.key] }}
                </div>
              </template>

              <template #cell(no)="data">
                <div>
                  {{ data.index + 1 }}
                </div>
              </template>

              <!-- ANCHOR issueDate -->
              <template #cell(issueDate)="{ item: { issueDate } }">
                <span class="text-nowrap">{{ convertISODateTime(issueDate).timeDate }}</span>
              </template>

              <!-- ANCHOR bookingDate -->
              <template #cell(bookingDate)="{ item }">
                <div class="text-nowrap">
                  {{ convertISODateTime(item.bookingDate).timeDate }}
                </div>
              </template>

              <!-- ANCHOR signBook -->
              <template #cell(signBook)="data">
                <div class="text-right text-nowrap">
                  {{ data.item.signBook }}
                </div>
              </template>

              <!-- ANCHOR priceTicket -->
              <template #cell(priceTicket)="{ item: { priceTicket } }">
                <div class="text-right text-nowrap">
                  {{ formatVnCurrency(priceTicket) }}
                </div>
              </template>

              <!-- ANCHOR collection fee -->
              <template #cell(collectionFee)="{ item: { collectionFee } }">
                <div class="text-right text-nowrap">
                  {{ formatVnCurrency(collectionFee) }}
                </div>
              </template>

              <!-- ANCHOR tax -->
              <template #cell(tax)="{ item }">
                <div class="text-right">
                  {{ formatVnCurrency(item.tax) }}
                </div>
              </template>

              <!-- ANCHOR otherTax -->
              <template #cell(otherTax)="{ item }">
                <div class="text-right">
                  {{ formatVnCurrency(item.otherTax) }}
                </div>
              </template>

              <!-- ANCHOR fee -->
              <template #cell(fee)="{ item }">
                <div class="text-right">
                  {{ formatVnCurrency(item.fee) }}
                </div>
              </template>

              <template #cell(feeIn)="{ item }">
                <div class="text-right">
                  {{ formatVnCurrency(item.feeIn) }}
                </div>
              </template>

              <template #cell(feeOut)="{ item }">
                <div class="text-right">
                  {{ formatVnCurrency(item.feeOut) }}
                </div>
              </template>

              <!-- ANCHOR feeService -->
              <template #cell(feeService)="{ item }">
                <div class="text-right">
                  {{ formatVnCurrency(item.feeService) }}
                </div>
              </template>

              <!-- ANCHOR commissionPrepaid -->
              <template #cell(commissionPrepaid)="{ item }">
                <div class="text-right">
                  {{ formatVnCurrency(item.commissionPrepaid) }}
                </div>
              </template>

              <!-- ANCHOR unpaidCommission -->
              <template #cell(unpaidCommission)="{ item }">
                <div class="text-right">
                  {{ formatVnCurrency(item.unpaidCommission) }}
                </div>
              </template>

              <!-- ANCHOR accruedDebit -->
              <template #cell(tradeCreditors)="{ item }">
                <div class="text-right">
                  {{ formatVnCurrency(item.tradeCreditors) }}
                </div>
              </template>

              <!-- ANCHOR accruedCredit -->
              <template #cell(receivables)="{ item }">
                <div class="text-right">
                  {{ formatVnCurrency(item.receivables) }}
                </div>
              </template>

              <!-- ANCHOR totalPayment -->
              <template #cell(totalPayment)="{ item: { totalPayment } }">
                <div class="font-weight-bold text-right">
                  {{ formatVnCurrency(totalPayment) }}
                </div>
              </template>

              <!-- ANCHOR agency -->
              <template #cell(agency)="data">
                <div class="mb-0 text-nowrap">
                  {{ data.item.agency.agencyCode }}
                </div>
              </template>

              <!-- ANCHOR profit: Lợi nhuận
            - với F1:
              + customer: feeService + commission
              + agency: feeIn - commissionPrepaidOut
            - với F2, F3:
              + receivables - tradeCreditors
          -->
              <template #cell(profit)="{ item }">
                <div class="text-right">
                  <!-- <div v-if="roleMama">
                    {{ formatVnCurrency(item.agency.id === 1000000 ? (item.feeService + item.commission) : (item.feeIn - item.commissionPrepaidOut) ) }}
                  </div> -->
                  <div>
                    {{ formatVnCurrency(item.receivables - item.tradeCreditors) }}
                  </div>
                </div>
              </template>
              <!-- ANCHOR createdAt -->
              <template #cell(createdAt)="data">
                <div class="mb-0 text-nowrap">
                  {{ convertISODateTime(data.item.createdAt).dateTime }}
                </div>
              </template>

              <!-- ANCHOR updatedAt -->
              <template #cell(updatedAt)="data">
                <div class="mb-0 text-nowrap">
                  {{ convertISODateTime(data.item.updatedAt).dateTime }}
                </div>
              </template>

              <!-- ANCHOR booker -->
              <template #cell(booker)="data">
                <div
                  v-if="data.item.booker"
                  class="mb-0 text-nowrap"
                >
                  {{ data.item.booker.username }}
                </div>
              </template>

              <!-- ANCHOR issuer -->
              <template #cell(issuer)="data">
                <div
                  v-if="data.item.issuer"
                  class="mb-0 text-nowrap"
                >
                  {{ data.item.issuer.username }}
                </div>
              </template>

            </b-table>
          </app-collapse-item>

        </app-collapse>
      </div>
    <!-- !SECTION -->
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BTable, BOverlay,
} from 'bootstrap-vue'
import { ref, computed, onUnmounted } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

import store from '@/store'
import { useRouter } from '@/@core/utils/utils'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  formatCurrency, convertISODateTime, getDate, formatVnCurrency,
} from '@core/utils/filter'

import accountsPayableF2StoreModule from '@accountsPayable/accounts-payable-f2/accountsPayableF2StoreModule'
import useAccountsPayableF2Handle from '@accountsPayable/accounts-payable-f2/useAccountsPayableF2Handle'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BRow,
    BCol,
    BOverlay,
    AppCollapse,
    AppCollapseItem,
  },
  setup() {
    const accountPayable = ref({})

    const ACCOUNTS_PAYABLE_F2_APP_STORE_MODULE_NAME = 'app-account-payable-f2'

    // Register module
    if (!store.hasModule(ACCOUNTS_PAYABLE_F2_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNTS_PAYABLE_F2_APP_STORE_MODULE_NAME, accountsPayableF2StoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNTS_PAYABLE_F2_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNTS_PAYABLE_F2_APP_STORE_MODULE_NAME)
    })

    const { route } = useRouter()
    const { query, params } = route.value
    const agencyCode = computed(() => (accountPayable.value?.items?.length ? accountPayable.value.items[0]?.code : ''))
    const agencyName = computed(() => (accountPayable.value?.items?.length ? accountPayable.value.items[0]?.agencyName : ''))
    const roleMama = computed(() => store.getters['userStore/getRoleMama'])
    const {
      exportAccountPayableDetail,
      tableDetailColumns,
      exportAccountPayableDetailF2,
    } = useAccountsPayableF2Handle()

    store.dispatch('app-account-payable-f2/fetchDetailAccountsPayableF2', {
      agencyId: params.id,
      params: {
        type: query.typeCode,
        code: query.code,
        startDate: query.startDate,
        endDate: query.endDate,
      },
    })
      .then(response => {
        accountPayable.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          accountPayable.value = undefined
        }
      })

    function confirmExport() {
      // show modal confirm export sale report
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmExport') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            exportAccountPayableDetailF2({
              agencyId: params.id,
              params: {
                type: query.typeCode,
                code: query.code,
                startDate: query.startDate,
                endDate: query.endDate,
              },
            })
          }
        })
    }

    return {
      formatCurrency,
      convertISODateTime,
      formatVnCurrency,
      getDate,

      route,
      tableDetailColumns,
      accountPayable,
      query,

      confirmExport,
      exportAccountPayableDetail,

      agencyCode,
      agencyName,
      isEmpty,

      roleMama,
    }
  },
}
</script>

<style lang="scss" scoped>
#detail-accounts-payable-card ::v-deep{
  .card.open .card-header {
    border-bottom: 1px solid #ebe9f1;
  }
}
</style>
